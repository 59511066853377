<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid" id="cj">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row" id="title">
            <h3 class="title">{{locale[lang].FAQ.TITLE}}</h3>
            <h5 class="subtitle">{{locale[lang].FAQ.SUBTITLE}}</h5>
          </div>
          <div class="row" id="search-form">
            <div class="input-group">
              <input class="form-control" id="search" type="text" :placeholder="locale[lang].FAQ.PLACEHOLDER" v-model="keyword"/>
              <button class="btn">
                <img class="img-fluid" src="@/assets/Images/Icon-search@2x.png" alt="search-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h3 class="sub">{{locale[lang].FAQ.SUBTITLE}}</h3>
        </div>
      </div>
      <div class="row" id="cards">
        <div class="col-12 col-lg-6" v-for="(item, key) in filteredItems" :key="key" @click="goTo(item.param)">
          <transition name="fade">
            <FaqItem :text="item.text" :img="img"/>
          </transition>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" :class="{ bcColor: footer }">
      <Footer/>
    </div>
  </div>
</template>

<script>
  import FaqItem from "@/components/FaqItem.vue";
  import NavBar from "@/components/essential/NavBar.vue";
  import Footer from "@/components/essential/Footer.vue";
  export default {
    components: {
      FaqItem,
      NavBar,
      Footer,
    },
    methods: {
      goTo(param) {
        this.$router.push({ name: "Question", params: { id: param } });
      },
    },
    created() {
      this.items = this.locale[this.lang].FAQ.ITEMS
      var w = window.innerWidth;
      if (w <= 1024) {
        this.filteredItems.sort(function (a, b) {
            return a.id - b.id;
        })
      }
    },
    data() {
      return {
        keyword: "",
        img: "information@2x.png",
        items: [],
      };
    },
    computed: {
      filteredItems() {
        return this.items.filter(el => {
          return el.text.trim().replace(" ", "").toLowerCase().includes(this.keyword.trim().replace(" ", "").toLowerCase());
        });
      },
      footer() {
        return this.$store.state.footer;
      }
    },
  };
</script>

<style scoped>
#cj {
  padding: 10px 140px 10px 140px !important;
  position: relative;
  background-image: url("../assets/Images/Background/Enmascarar grupo 129-blue.png"),
    url("../assets/Images/Background/Grupo 691.png"),
    url("../assets/Images/Background/Trazado 1903.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 95%, 92% 90%, 95% 15%;
}
.img-fluid {
  width: 27px;
  height: 27px;
}
.title {
  text-align: center;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #0e0e0e;
  opacity: 1;
}
.subtitle,
.sub {
  font: normal normal 600 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #0e0e0e;
  opacity: 1;
}
.subtitle {
  text-align: center;
}
.sub {
  text-align: left;
  margin: 80px 0px;
}
#title {
  display: block;
  margin-bottom: 40px;
}
#search {
  border: none;
  height: 74px;
  border-radius: 15px;
}
#search::placeholder {
  text-align: left;
  font: normal normal 500 21px/25px Montserrat;
  letter-spacing: 0px;
  color: #1e1e1e;
  opacity: 1;
}
#search-form {
  display: block;
}
.input-group {
  width: 550px;
  height: 74px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  left: 24%;
}
#cards {
  margin-bottom: 150px;
}
/* #cards >>> .item {
  width: 780px !important;
} */
@media (max-width: 1024px) {
  #title {
    margin-top: 40px;
  }
  #cj {
    padding: 0 !important;
  }
  #cj {
    background-size: 25%;
  }
  .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .title,
  .subtitle {
    font: normal normal 800 20px/24px Montserrat;
    margin-bottom: 0;
  }
  .subtitle {
    font-weight: 600 !important;
  }
  .sub {
    display: none;
  }
  .row {
    justify-content: center;
  }
  .input-group {
    width: 365px;
    height: 39px;
    left: unset;
  }
  #search {
    width: 270px;
    height: 39px;
  }
  .input-group img {
    width: 18px;
    height: 18px;
  }
  #search::placeholder {
    font: normal normal 500 12px/15px Montserrat;
  }
  #cards {
    margin-bottom: 0;
    padding: 40px 30px;
  }
  #cj >>> .sm-item {
    margin-bottom: 10px;
  }
  #cj >>> .sm-item .item img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
  #cj >>> .sm-item h3 {
    font: normal normal bold 16px/20px Montserrat !important;
    margin-bottom: 0;
  }
  #search-form {
    display: flex;
  }
}
@media (max-width: 425px) {
  .input-group {
    width: 326px;
  }
}
@media (max-width: 375px) {
  .input-group {
    width: 326px;
  }
}
@media (min-width: 1920px) {
  .input-group {
    width: 894px;
  }
}
</style>